.div_column {
  display: flex;
  flex-direction: column;
}

.div_row {
  display: flex;
  flex-direction: row;
}

.align_center {
  align-items: center;
}


.justify_center {
  justify-content: center;
}

.fill_parent {
  flex: 1;
  align-self: stretch;
}

.align_stretch {
  align-items: stretch;
}

.align_start {
  align-items: flex-start;
}

.align_end {
  align-items: flex-end;
}

.justify_stretch {
  justify-content: stretch;
}

.justify_end {
  justify-content: flex-end;
}

.justify_space_between {
  justify-content: space-between;
}

.justify_space_evenly {
  justify-content: space-evenly;
}

.align_self_stretch {
  align-self: stretch;
}

.align_self_center {
  align-self: center;
}

.align_self_end {
  align-self: flex-end;
}
